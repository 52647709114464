<template>
  <div class="legals">
    <b-container class="legals-content text-center">
      <h2>Términos y condiciones</h2>

      <b-row class="d-flex justify-content-center">
        <b-col md="12" class="information mt-5" style="text-align: justify">
          <p>
            BeeTelco S.A. de C.V., en adelante “Bee Telecom”, con domicilio en
            Calle 10 Norte 603 Oficina privada 4A, San Pedro Cholula, Puebla
            C.P. 72750, en cumplimiento y ejercicio de los derechos de acceso a
            la información, y acceso a las tecnologías de la información y
            comunicación, de conformidad con el artículo 6 de la Constitución
            Política Mexicana, hace de su conocimiento que los términos y
            condiciones de uso aquí mencionados ("Términos y Condiciones"),
            regulan el uso y la actividad en el sitio de internet
            www.beetelecom.mx (en adelante el "Sitio"), en donde BeeTelco S.A.
            de C.V., (en adelante “Bee Telecom”) brinda información sobre los
            servicios que presta así como los medios de comunicación para dar
            seguimiento a los servicios contratados. Los Términos y Condiciones
            son obligatorios para todos los usuarios que naveguen en el Sitio,
            por lo que usted reconoce haber leído, comprendido y manifiesta su
            voluntad de obligarse a los mismos, así como cumplir todas las
            normas y leyes aplicables a dichos Términos y Condiciones, de lo
            contrario deberá abstenerse de utilizar este Sitio.
          </p>

          <br />

          <p>
            Con el propósito de brindar los servicios de calidad que usted
            requiere como cliente y/o usuario, para procesar sus pagos, generar
            y enviar sus facturas, brindarle información y asesoría, darle
            seguimiento a quejas y/o sugerencias, así como, envío de publicidad,
            ofertas comerciales y nuevos servicios, Bee Telecom podrá tener
            acceso a cierta información que puede o no ser considerada como
            información confidencial o datos personales del cliente y/o usuario,
            en tal sentido Bee Telecom se obliga a tratar dicha información y/o
            datos personales de conformidad con el respectivo aviso de
            privacidad publicado en el Sitio, así como también por lo dispuesto
            en la Ley Federal de Protección de Datos Personales en Posesión de
            los Particulares y su Reglamento. Derivado de lo anterior, el
            cliente y/o usuario del Sitio se obliga a que la información que
            pueda llegar a proporcionar a Bee Telecom sea verídica, fidedigna,
            completa y exacta.
          </p>

          <br />

          <p>
            Asimismo, el cliente y/o usuario se obliga a abstenerse de realizar
            actividades que puedan dañar, inutilizar o deteriorar los sistemas
            informáticos del Sitio, los contenidos incorporados y/o almacenados
            en su sistema, modificar en forma alguna los sistemas del Sitio,
            utilizar versiones de sistemas modificados con el fin de obtener
            acceso no autorizado a cualquier sistema de información, contenido
            y/o servicio de la página www.beetelecom.mx, e interferir, o
            irrumpir el acceso, funcionalidad y utilización de la página web de
            Bee Telecom, servidores o redes conectadas a la misma.
          </p>

          <br />

          <p>
            Los servicios o la información que se proporciona a través del Sitio
            y todo el material disponible que se encuentre en el Sitio, son
            propiedad exclusiva de Bee Telecom, por lo que el cliente y/o
            usuario del Sitio garantiza a Bee Telecom que no empleará el Sitio o
            la información contenida en este, con ninguna finalidad de cometer o
            realizar un acto ilícito.
          </p>

          <br />

          <p>
            El Sitio contiene material protegido en materia de la legislación de
            propiedad intelectual, en el entendido que Bee Telecom es titular de
            los derechos y/o licencias sobre dicho material, por lo cual usted
            se obliga a no emplear dicho material y respetar las normas en
            materia de propiedad intelectual, quedando estrictamente prohibida
            la reproducción, copia, distribución, modificación, alteración en
            forma total o parcial del contenido, información, marca y/o software
            empleado en el Sitio.
          </p>

          <br />

          <p>
            El cliente y/o usuario será el único responsable de los daños y
            perjuicios de toda naturaleza que Bee Telecom pueda sufrir, directa
            o indirectamente, como consecuencia del incumplimiento de cualquiera
            de las obligaciones de los Términos y Condiciones y/o de la ley o
            reglamento aplicable. Bee Telecom no garantiza que la información de
            este Sitio sea correcta, esté actualizada ni sea completa, y
            advierte que este Sitio puede contener imprecisiones técnicas o
            errores tipográficos, por lo que Bee Telecom no asume ninguna
            responsabilidad sobre la actualización del Sitio, por lo tanto,
            usted se obliga a confirmar que la información publicada sea precisa
            y completa enviando un correo electrónico a contacto@beetelecom.mx,
            antes de tomar alguna decisión relacionada con cualquier servicio o
            tema descrito en este Sitio.
          </p>

          <br />

          <p>
            Bee Telecom, cuenta con medidas de seguridad administrativas,
            técnicas y físicas, utilizando el protocolo “https” para brindarle
            seguridad en el uso de sus Servicios.
          </p>

          <br />

          <p>
            Asimismo, Bee Telecom podrá poner a su disposición dentro del Sitio,
            vínculos a sitios de internet, páginas y/o aplicaciones; sin
            embargo, estos enlaces se proporcionan sólo con fines informativos,
            por lo que los contenidos o recursos de esos sitios de internet,
            páginas y/o aplicaciones o incluso el tratamiento de datos que en
            ellas se hagan, serán responsabilidad exclusiva de dichos sitios de
            internet, páginas y/o aplicaciones; asimismo, usted deberá tomar sus
            propias precauciones para asegurar que durante el acceso al Sitio,
            no se exponga a riesgos informáticos, virus informáticos u otras
            amenazas informáticas, que puedan dañar su equipo de cómputo o
            dispositivo móvil; en este sentido, Bee Telecom, no se hará
            responsable por cualquier falla en las medidas de seguridad cuando
            dicho incumplimiento se deba a circunstancias fuera de su control,
            caso fortuito o fuerza mayor.
          </p>

          <br />

          <p>
            Bee Telecom utilizará cookies y web beacons para mejorar su
            experiencia y poder hacer más eficientes los servicios, asimismo,
            Bee Telecom podrá recabar su dirección IP, y usar cualquiera de
            estas herramientas tecnológicas con fines estadísticos, de
            conformidad con lo establecido en el aviso de privacidad de Bee
            Telecom.
          </p>

          <br />

          <p>
            En caso de cualquier comentario, duda, reclamación o aclaración
            relacionados con los Términos y Condiciones presentes, el usuario
            podrá contactar a Bee Telecom mediante un correo electrónico a
            contacto@beetelecom.mx.
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Terminos",

  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.legals {
  background: #fecd00;
}

.legals .legals-content {
  padding-top: 150px;
  padding-bottom: 150px;
}

.legals .legals-content h2 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 40px;
  text-shadow: 1px 1px black, -1px -1px #ffffff;
}

.legals .legals-content p {
  font-weight: 600;
  font-size: 15px;
}

.legals .legals-content p b {
  color: #ffffff;
  font-size: 17.5px;
}
</style>
